<template>
  <div>
     <base-dialog
      :visible.sync="dialogVisible"
      :showFooter="false"
      title="在线编辑"
      class="onlineEditDialog"
    >
      <!-- <base-button icon="iconfont iconxiazai" label="文件下载" @click="dowload()"></base-button></div> -->
      <!-- <pre-view :count="count" :fileId="fileId" :fileType="fileType"></pre-view> -->
      <div class="content">
      <online-edit v-if="dialogVisible" :count="count" :fileId="fileId" :fileType="fileType"></online-edit>
      </div>
      <template slot="footer">
        <base-button
          label="提 交"
          @click="submit"
        ></base-button>
        <base-button
          label="关 闭"
          type="default"
          @click="close"
        ></base-button>
      </template>
    </base-dialog>
  </div>
</template>
<script>
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
// import preView from '@/components/pre-view/pre-view.vue'
import { fileListApi } from '@/api/businessApi'
// import Storage from '@/utils/storage'
import { fileAPi } from '@/api/fileApi'

import onlineEdit from '@/components/online-edit/online-edit.vue'
export default {
  components: { BaseDialog, BaseButton, onlineEdit },
  props: {
    onlineEditVisible: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 0
    },
    fileId: {
      type: String,
      default: ''
    },
    fileType: {
      type: String,
      default: ''
    },
    fileEditData: {
      type: Object
    }
  },
  data () {
    return {

    }
  },
  computed: {
    dialogVisible: {
      get () {
        return this.onlineEditVisible
      },
      set (val) {
        this.$emit('update:onlineEditVisible', val)
      }
    }
  },
  mounted () {
    // Object.defineProperty(XMLHttpRequest.prototype, 'status', {
    //   get: function (status) {
    //     console.log({
    //       url: this.responseURL,
    //       data: this.response
    //     })
    //   }
    // })
  },
  methods: {
    async submit () {
      const data = { keyId: this.fileEditData.fileId, templateCode: 'TEMPLATE' }
      let timeCount = 0
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.2)'
      })
      let res = {}
      const timer = await setInterval(() => {
        res = fileAPi.fileSave(data)
        timeCount++
        // 超时提示
        if (timeCount > 10) {
          loading.close()
          clearInterval(timer)
          this.warning('请稍后重试,文件未修改或文件服务变更未同步完成!')
          return
        }
        // 结果不为-1,跳出轮询调用保存接口
        if (res.data.keyId !== '-1') {
          loading.close()
          clearInterval(timer)
          const newData = this.fileEditData
          newData.fileId = res.data.fileId
          newData.operateType = '30'
          fileListApi.contractsEdit(newData).then(res => {
            if (res.data) {
              this.success('提交成功')
              this.$parent.$parent.getdetail()
              this.dialogVisible = false
            }
          })
        }
      }, 3000)
    },
    close () {
      this.$parent.$parent.getdetail()
      this.dialogVisible = false
    }

  }
}
</script>
<style lang="scss" scoped>
/deep/.el-dialog{
  width: 90% !important;
  height: 95% !important;
}
.content{
  height: 100%;
}
.dowlaodbtn{
  padding: 5px 10px;
  text-align: right;
}
</style>
